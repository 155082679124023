import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {NavigationEnd, Router} from '@angular/router';
import {UIService} from '../../services/ui.service';
import {UserCached, UserService} from '@isifid/core';
import {EmailService} from '@isifid/reward';
import {OperationsService} from '../../services/operations.service';
import {SponsorshipService} from '../../services/sponsorship.service';
import {GiftService} from '../../services/gift.service';
import {SidenavItem} from '../../models/sidenav-item.model';
import {Observable} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['sidenav.component.scss'],
    standalone: false
})
export class SidenavComponent implements OnInit {
    cdnUrl = environment.cdnUrl + '/gift/img/';

    menus: SidenavItem[] = [
        {
            name: 'Accueil',
            iconClass: 'fa fa-home',
            active: false,
            url: '/admin/dashboard',
            submenu: []
        },
        {
            name: 'Opérations',
            iconClass: 'fa fa-eye',
            active: false,
            submenu: [
                {name: 'Statistiques Opérations', url: '/admin/stats/operations', active: false},
                {name: 'Créer une opération', url: '/admin/operations/request', active: false}
            ]
        },
        {
            name: 'Parrainage ',
            iconClass: 'fa fa-eye',
            active: false,
            submenu: [
                {name: 'Tableau de bord ', url: '/admin/stats/sponsorship', active: false}
            ]
        }
    ];

    private user: UserCached;

    constructor(
        public uiService: UIService,
        public readonly sponsorshipService: SponsorshipService,
        public readonly operationsService: OperationsService,
        public readonly userService: UserService,
        public readonly giftService: GiftService,
        public readonly router: Router,
        private readonly emailService: EmailService
    ) {
        router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe({
                next: (e: NavigationEnd) => {
                    this.uiService.isSidenavOpened = false;
                    this.selectMenuItem(e.url);
                }
            });
    }

    ngOnInit(): void {
        this.selectMenuItem(this.router.url);
        this.user = this.userService.getUser();
        this.addMenuItemForNetworkUsers();
    }

    toggleSidenav(): void {
        this.uiService.isSidenavOpened = !this.uiService.isSidenavOpened;
    }

    toggle(index: number) {
        this.menus
            .filter((menu, i) => i !== index && menu.active)
            .forEach(menu => (menu.active = !menu.active));
        this.menus[index].active = !this.menus[index].active;
    }

    sendFile(target: EventTarget): void {
        const file: File = (target as HTMLInputElement).files?.[0];
        this.fileToBase64(file)
            .pipe(
                switchMap(attachment => {
                    return this.emailService.send(
                        'sysadmin@isifid.com',
                        '',
                        environment.isifidDefaultRecipient,
                        'Un nouveau fichier a été envoyé par Gift',
                        `Utilisateur: ${this.user.firstName} ${this.user.lastName} <br> Client: ${this.giftService.client.name}`,
                        [attachment],
                        ''
                    );
                })
            )
            .subscribe();
    }

    private fileToBase64(file: File): Observable<{content: any; filename: string; type: string}> {
        return new Observable(o => {
            const reader = new FileReader();
            reader.readAsDataURL(file as Blob);
            reader.onload = () => {
                // Extracting only the Base64 content
                const base64String = reader.result?.toString().split(',')[1];
                o.next({
                    content: base64String,
                    filename: file.name,
                    type: file.type
                });
            };
            reader.onerror = error => o.error(error);
        });
    }

    private selectMenuItem(url: string): void {
        this.menus.forEach((s )=> {
            s.submenu.forEach(t => t.active = url.startsWith(t.url));
            s.active = url.startsWith(s.url) || s.submenu.some(t => t.active);
        });
    }

    private addMenuItemForNetworkUsers(): void {
        if (this.giftService.hierarchicalLevels?.length) {
            this.menus.push(
                {
                    name: 'Réseau',
                    iconClass: 'fa fa-eye',
                    active: false,
                    submenu: [
                        {name: 'Liste des utilisateurs ', url: '/admin/network/users', active: false}
                    ]
                }
            );
        }
    }
}
