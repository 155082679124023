import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogRewardConfirmationOperationComponent} from '../../admin/reward/reward.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormValidators, GiftSettings, MsServicesGiftService, UserCached} from '@isifid/core';
import {finalize} from 'rxjs';
import {GiftService} from '../../../shared/services/gift.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-dialog-personal-link',
    templateUrl: './dialog-personal-link.component.html',
    styleUrls: ['./dialog-personal-link.component.scss'],
    standalone: false
})
export class DialogPersonalLinkComponent implements OnInit {
    formGroup: FormGroup;
    settings: GiftSettings;
    loading: boolean;
    accessSent: boolean;
    showError: boolean;
    constructor(
        private readonly router: Router,
        private readonly formBuilder: FormBuilder,
        private readonly formValidators: FormValidators,
        private readonly giftService: GiftService,
        private readonly msServicesGiftService: MsServicesGiftService,
        private dialogRef: MatDialogRef<DialogRewardConfirmationOperationComponent>,
        @Inject(MAT_DIALOG_DATA) public user: UserCached
    ) {
    }

    ngOnInit() {
        this.settings = this.giftService.settings;
        this.formGroup = this.formBuilder.group({
            // Force email to contain a dot before @ : .+[.]+.+[@].+
            // Allow one number before @ : ^[^0-9]+[0-9]{0,1}(@){1}[^0-9]+
            email: [
                this.settings?.emailAddressRoot || '',
                [Validators.required, Validators.email, this.formValidators.isEmail, Validators.pattern('^[^0-9]+[0-9]{0,1}(@){1}[^0-9]+')]
            ]
        });
    }

    goToLogout(): void {
        this.dialogRef.close();
        this.router.navigate(['logout']).then();
    }

    getAccess(): void {
        if (this.loading) return;
        if (this.formGroup.invalid) {
            this.showErrors();
            return;
        }

        this.loading = true;
        let url = this.giftService.giftNetworkVariables.url;
        if (!url || url === '') url = window.location.origin + '/auth';
        let emailSender = this.giftService.giftNetworkVariables.emailSender;
        if (!emailSender || emailSender === '') emailSender = null;

        const accessData = {
            clientId: this.settings.clientId,
            email: this.formGroup.get('email').value,
            emailSender,
            url
        };

        // Init login
        this.initLogin(accessData);
    }

    private initLogin(accessData) {
        // Init login
        this.msServicesGiftService.getAccess(accessData)
            .pipe(finalize(() => this.loading = false))
            .subscribe({
                next: () => this.accessSent = true,
                error: () => this.showError = true
            });
    }

    private showErrors(): void {
        for (const control in this.formGroup.controls) {
            if (this.formGroup.get(control).invalid) {
                this.formGroup.get(control).markAsDirty();
            }
        }
        // Got focus to the error field
        const invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
        invalidFields[0].focus();
    }
}
