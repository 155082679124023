import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'dateBefore',
    standalone: false
})
export class DateBeforePipe implements PipeTransform {
    transform(value: Date | string | number, daysBefore: number): Date {
        const date = new Date(value);
        date.setDate(date.getDate() - daysBefore);
        return date;
    }
}
