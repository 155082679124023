import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {CookiesService, GiftSettings, GiftUser} from '@isifid/core';
import {GiftService} from '../../../shared/services/gift.service';
import {GiftUserService} from '../../../shared/services/gift-user.service';
import {MatChipInputEvent} from '@angular/material/chips';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {finalize} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {filter, map} from 'rxjs/operators';

interface DialogData {
    branchCodes: string[];
}

@Component({
    selector: 'app-dialog-update-branch-code',
    templateUrl: './dialog-update-branch-code.component.html',
    styleUrls: ['./dialog-update-branch-code.component.scss'],
    standalone: false
})
export class DialogUpdateBranchCodeComponent implements OnInit {
    formGroup: FormGroup;
    settings: GiftSettings;
    branchCodes: string[];
    loading: boolean;
    accessSent: boolean;
    showError: boolean;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    private giftUser: GiftUser;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: DialogData,
        private readonly dialogRef: MatDialogRef<DialogUpdateBranchCodeComponent>,
        private readonly giftService: GiftService,
        private readonly giftUserService: GiftUserService,
        private readonly formBuilder: FormBuilder,
        private readonly cookiesService: CookiesService,
    ) {
    }

    ngOnInit() {
        this.cookiesService.setCookie('last_branchcode_check', new Date());
        this.settings = this.giftService.settings;
        this.giftUserService.getGiftUser().pipe(filter(s => !!s), map(s => ({...s, branchList: s.branchList ?? []}))).subscribe(s => {
            this.giftUser = s;
            this.branchCodes = s.branchList.map(s => this.giftService.convertBranchCodeToString(s));
        });
        this.formGroup = this.formBuilder.group({
            branch: [this.branchCodes, [this.branchListValidator(), Validators.required]]
        });
    }

    closeDialog(): void {
        this.dialogRef.close(this.branchCodes.toString() !== this.data?.branchCodes.toString());
    }

    addBranchCode(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) this.formGroup.controls.branch.setValue([...this.formGroup.controls.branch.value, value]);

        event.chipInput.clear();
    }

    removeBranchCode(branchCode: string): void {
        const index = this.formGroup.controls.branch.value.indexOf(branchCode);

        if (index >= 0) {
            const branchCodes = this.formGroup.controls.branch.value;
            branchCodes.splice(index, 1);
            this.formGroup.controls.branch.setValue([...branchCodes]);
        }
    }


    updateBranchCode(): void {
        if (this.loading) return;

        if (this.formGroup.invalid) {
            this.showErrors();
            return;
        }

        this.loading = true;
        const branch = this.formGroup.get('branch').value.map((s: string) => parseInt(s));
        this.giftUserService.updateGiftUserBranchList(this.giftUser, branch)
            .pipe(finalize(() => this.loading = false))
            .subscribe({
                next: () => {
                    this.accessSent = true;
                    this.branchCodes = branch;
                },
                error: () => this.showError = true
            });
    }

    private showErrors(): void {
        for (const control in this.formGroup.controls) {
            if (this.formGroup.get(control).invalid) {
                this.formGroup.get(control).markAsDirty();
            }
        }
        // Got focus to the error field
        const invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
        invalidFields[0].focus();
    }

    private branchListValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value || !Array.isArray(control.value)) {
                return null;
            } else {
                const regExp = /^\d*$/;
                return control.value.every(s => regExp.test(s)) ? null : {invalid: true};
            }
        };
    }
}
